import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'
import { IsMobile } from '../layout'

export default function CfaBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                <h1> Sign your CFA</h1>
                <p>
                    Please read and sign your 'Contingency Fee Agreement' form to continue your motor finance claim with
                    Sentinel Legal Ltd.
                </p>
            </Wrapper>
        </div>
    )
}

import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { Wrapper } from '../components/reusable'
import CfaBanner from '../components/cfaBanner'
import SingleSignatureContainer from '../components/singleSigContainer'
import { apiErrors } from '../helpers/apiErrors'
import PocView from '../components/pocView'
import { useUpdateClaimData } from '../claimProvider'
import DualSignatureContainer from '../components/dualSigContainer'
import PocSidebar from '../components/pocSidebar'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'

export default function CfaUpdateOpopClients() {
    const [cfaData, setCfaData] = useState([])
    const [retrievedCfa, setRetrievedCfa] = useState(false)
    const [currentClaim, setCurrentClaim]: any = useState({})
    const { token } = useParams()
    const updateClaim = useUpdateClaimData()

    useEffect(() => {
        if (!retrievedCfa) {
            Loading.standard('Loading...')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/client-update-opop`, { token })
                .then((response) => {
                    const responseData = response.data

                    if (responseData == 'all CFAs Complete') {
                        Report.success(
                            'No Documents Available',
                            "You don't have any documents to sign.",
                            'Okay',
                            () => {
                                window.location.href = 'https://sentinellegal.co.uk'
                            }
                        )
                    }

                    const signatureData = responseData.signatureDataResult

                    if (!signatureData) throw new Error(responseData.error)
                    console.log(signatureData)

                    localStorage.setItem('name', `${signatureData.first_name} ${signatureData.last_name}`)

                    updateClaim(signatureData)
                    setCfaData(signatureData)
                    setCurrentClaim(signatureData)

                    Loading.remove()
                })
                .catch((e) => {
                    console.error('error:', e)
                    const serverError = e.response.data
                    Loading.remove()
                    apiErrors(serverError)
                })
        }
    }, [])

    useEffect(() => {
        console.log('cc : ', currentClaim)

        setRetrievedCfa(true)
    }, [currentClaim])
    const completeClientUpdate = (obj: any) => {
        Report.success(
            'Conditional Fee Agreement Complete',
            'Thank you for signing your documents. We will update you on your cases in due course.',
            'Okay',
            () => {
                window.location.href = 'https://sentinellegal.co.uk'
            }
        )

        return
    }

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <CfaBanner />
            <Wrapper>
                <div>
                    <h2>What am I Signing?</h2>
                    <p>
                        We (Sentinel Legal) are requesting your signature for our terms and Form of authority in regards
                        to your missold PCP motor finance claim. <br /> <br />
                        You have previously submitted an application via our marketing website (missold-finance.co.uk),
                        specifically on the claim application web form. <br /> <br />
                        For Sentinel Legal to move forward with your claim and make a full assessment, we require your
                        signature. Please review and sign our conditional fee agreement using the signature pad below.
                    </p>
                </div>
                <div>
                    {retrievedCfa ? (
                        <DualSignatureContainer
                            signedAction={completeClientUpdate}
                            leftPreviewUrl={currentClaim.cfa_doc_url}
                            rightPreviewUrl={currentClaim.foa_doc_url}
                            extraPayload={{
                                type: 'fos-cfa-signature-opop',
                                metadata: { browser: browserName, device: osName },
                            }}
                        />
                    ) : (
                        <></>
                    )}
                </div>
            </Wrapper>
        </div>
    )
}

import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

export interface PocViewProps {
    children: React.ReactNode;
}

const PocView = (props: PocViewProps) => {
    const { children } = props;
    return <div className={styles.pocView}>{children}</div>;
};

export default PocView;

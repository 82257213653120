import styles from "./styles.module.scss";
import pageStyles from "../../../globals/pages.module.scss";
import responsiveStyles from "./responsive-styles.module.scss";
import { Wrapper } from "../../reusable/";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/logo.png";
import profile from "../../../assets/profile.png";
import { IsMobile } from "../isMobile/isMobile";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useClaimData } from "../../../claimProvider";

function Header() {
    const claim = useClaimData();
    const [clientName, setClientName] = useState("");
    const { token } = useParams();
    useEffect(() => {
        if (typeof claim != "undefined") {
            console.log("claim in header: ", claim);
            setClientName(`${claim.first_name} ${claim.last_name}`);
        }
    }, [claim]);
    //45931d92-7a62-46ab-b959-65dccc8922a2
    return (
        <header className={`${styles.header}  ${responsiveStyles.header} `}>
            <Wrapper>
                <div className={`${styles.innerHeader} ${responsiveStyles.innerHeader} `}>
                    <div className={`${styles.portalUserInfo} ${responsiveStyles.portalInfo} `}>
                        {/* <div className={`${styles.profileImgContainer}`}>
                            <img src={profile} alt="portal profile" />
                        </div> */}
                        {IsMobile() ? (
                            <div className={responsiveStyles.initialsCircle}>
                                <p>
                                    {clientName
                                        .split(" ")
                                        .map((e) => {
                                            return e.charAt(0);
                                        })
                                        .join("")}
                                </p>
                            </div>
                        ) : (
                            <>
                                <div className={`${styles.profileImgContainer}`}>
                                    <img src={profile} alt="portal profile" />
                                </div>
                                <p>{clientName}</p>
                            </>
                        )}
                    </div>
                    <div className={`${styles.logo} ${responsiveStyles.logo} `}>
                        <a href="https://www.sentinellegal.co.uk" target="__blank">
                            <img src={logo} alt="Sentinel Legal Logo" />
                        </a>
                    </div>
                </div>
            </Wrapper>
        </header>
    );
}

export default Header;

import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Wrapper } from "../reusable";
import { IsMobile } from "../layout";

function Part36Banner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                {IsMobile() ? (
                    <h1>
                        Review and Sign the <br />
                        Part36 Document
                    </h1>
                ) : (
                    <h1> Review and Sign the Part36 Document</h1>
                )}
                <p>Please read and sign your 'Part36 Document' to continue your motor finance claim with Sentinel Legal Ltd.</p>
            </Wrapper>
        </div>
    );
}

export default Part36Banner;

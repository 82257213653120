import { useEffect } from "react";
import styles from "./styles.module.scss";

interface sigProps {
    allPocData: any;
    currentPoc: any;
    selectSidebarItem: (obj: any) => void;
}

function PocSidebar({ currentPoc, allPocData, selectSidebarItem }: sigProps) {
    //<Previewer url={preview} />;
    console.log(allPocData);

    useEffect(() => {}, [currentPoc]);
    return (
        <div className={styles.pocSidebar}>
            <ul>
                {allPocData.map((obj: any, k: number) => {
                    return (
                        <li
                            key={k}
                            onClick={() => selectSidebarItem(obj)}
                            className={`${obj.case_ref == currentPoc.case_ref ? styles.selected : ""}`}
                        >
                            Case Reference
                            <span>{obj.case_ref}</span>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default PocSidebar;

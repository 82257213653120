
import { Report } from "notiflix/build/notiflix-report-aio";
import { Loading } from "notiflix";
import axios from "axios";

interface uploadInterface {
    type: string;
    token?: string | undefined;
    fileToUpload?: File | undefined;
    completeStep: (type: number) => void;
}


export const uploadFileToServer = async ({ type, token, fileToUpload, completeStep }: uploadInterface) => {
    if (!token) {
        Loading.remove();
        Report.failure("Error Ocurred", "Please refresh the page and try again.", "okay");
        return;
    }

    if (!fileToUpload) {
        Loading.remove();
        Report.failure("Error Ocurred", "Please refresh the page and try again.", "okay");
        return
    }


    Loading.standard("Uploading file.");
    const formData = new FormData();
    formData.append("type", type);
    formData.append("token", token);
    formData.append("file", fileToUpload, "file");

    try {
        const response = await axios({
            method: "post",
            url: `${process.env.REACT_APP_API_UPLOAD_URL}`,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        });
        console.log("FILE UPLOAD RESPONSE: ", response.data);

        if (response?.data === "SUCCESS") {
            Loading.remove();
            if (type == 'poa') {
                completeStep(2);
            } else if (type == 'poid') {
                completeStep(3);
            }
        } else {
            throw new Error(response?.data);
        }
    } catch (error: any) {
        Loading.remove();

        console.log("ERROR IS : ", error);
        if (error.message === "No file provided") {
            Report.failure("No file selected", "Please add a file and try again.", "okay");
        } else if (error.message === "Extension not accepted") {
            Report.failure("Incorrect file type", "Please check the file type and try again.", "okay");
        } else if (error.message === "File exceeds 10MB") {
            Report.failure("File exceeds 10MB", "Please check the file and try again.", "okay");
        } else {
            Report.failure("Error Ocurred", "Please refresh the page and try again.", "okay");
        }
    }
    return true
}
import React, { useEffect, useState } from "react";
import AppRouter from "./router";
import "../src/globals/root.scss"; // Import regular stylesheet
import { ClaimProvider } from "./claimProvider";

function App() {
    // APP COMPONENT
    return (
        <ClaimProvider>
            <div className="App">
                <AppRouter />
            </div>
        </ClaimProvider>
    );
}

export default App;

import styles from "../globals/pages.module.scss";
import pageStyles from "./styles.module.scss";

import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../components/reusable";
import PocBanner from "../components/pocBanner";
import PocSignatureContainer from "../components/pocContainer";
import PocSidebar from "../components/pocSidebar";
import { apiErrors } from "../helpers/apiErrors";
import PocView from "../components/pocView";
import { useUpdateClaimData } from "../claimProvider";

function Poc() {
    const [pocData, setPocData] = useState([]);
    const [retrievedPoc, setRetrievedPoc] = useState(false);
    const [currentClaim, setCurrentClaim] = useState({});
    const { token } = useParams();
    const updateClaim = useUpdateClaimData();
    useEffect(() => {
        if (!retrievedPoc) {
            Loading.standard("Loading...");
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}`, { token })
                .then((response) => {
                    const pocData = response.data;
                    // console.log("NETWORK RESPONSE: ", pocData);
                    const signatureData = pocData.signatureDataResult;
                    console.log("signatureData:", signatureData);
                    if (!signatureData) throw new Error(pocData.error);
                    localStorage.setItem("name", `${signatureData[0].first_name} ${signatureData[0].last_name}`);
                    const startingClaim = signatureData.find((e: any) => e.status == "POC v3 Link sent");
                    console.log("STARTING CLAIM: ", startingClaim);
                    setPocData(signatureData);
                    setCurrentClaim(startingClaim);
                    setRetrievedPoc(true);
                    updateClaim(signatureData[0]);

                    Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    const serverError = e.response.data;
                    Loading.remove();
                    apiErrors(serverError);
                });
        }
    }, []);

    const completePoc = (obj: any) => {
        console.log("completePoc");

        let pocArray: any = [];
        pocArray = pocData.map((e: any) => {
            const updateClaim: any = currentClaim;
            updateClaim.status = "POC Signed";
            if (e.case_ref == updateClaim.case_ref) return updateClaim;
            return e;
        });

        const remainingClaim = pocArray.find((e: any) => e.status == "POC v3 Link sent");

        if (!remainingClaim) {
            Report.success(
                "POC & N1 Complete",
                "Thank you for signing all your documents. We will update you on your cases in due course.",
                "Okay",
                () => {
                    window.location.href = "https://sentinellegal.co.uk";
                }
            );
            return;
        }
        setPocData(pocArray);

        Report.success(
            "Document Signed",
            "Thank you for signing your POC and N1 form. Please click okay to review and sign your next case.",
            "Okay",
            () => {
                setCurrentClaim(remainingClaim);
            }
        );
    };

    const selectSidebarItem = (obj: any) => {
        console.log("selectSidebarItem");
    };

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            {/* <div className={styles.underMaintance}>
                <h1>
                    Sentinel Legal Portal <br />
                    Currently Under Maintanance
                </h1>
            </div> */}
            <PocBanner />
            <Wrapper>
                <h1>N1 form & Particulars of Claim's</h1>

                <p>
                    {pocData.length > 1
                        ? `Sentinel Legal have prepared your government issue N1 claim forms and the particulars of your ${pocData.length} motor vehicle finance claims.`
                        : `Sentinel Legal have prepared your government issue N1 claim form and the particulars of your motor vehicle finance claim.`}
                </p>
                <p>
                    In order for us to proceed with you claim, you are required to review and sign particulars of each claim along with signing your
                    court issued N1 claim form.
                </p>
                <p>
                    Once the particulars have been confirmed, our team at Sentinel Legal will proceed to issue your documents to the courts on your
                    behalf.
                </p>
                <hr />
                <PocView>
                    <PocSidebar currentPoc={currentClaim} allPocData={pocData} selectSidebarItem={selectSidebarItem} />
                    <PocSignatureContainer pocSignedAction={completePoc} currentPoc={currentClaim} type="poc-v3-signature"/>
                </PocView>
            </Wrapper>
        </div>
    );
}

export default Poc;

import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'
import { IsMobile } from '../layout'

export default function OpenBankingBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                <h1>Providing Your Consent for an Affordability Assessment</h1>
                <p>
                    The most secure and accurate method of analysing the affordability of your missold pcp car finance
                    claim,
                    <br />
                    all while <b>you stay in complete control of your data</b>.
                </p>
            </Wrapper>
        </div>
    )
}

import styles from "./styles.module.scss";
import StepOne from "./steps/one";
import StepTwo from "./steps/two";
import StepThree from "./steps/three";
import StepSelector from "./stepSelector";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Loading } from "notiflix";
import { useParams } from "react-router-dom";
import { apiErrors } from "../../helpers/apiErrors";
import Previewer from "../reusable/previewer";
import Modal from "../reusable/modal";
import { Report } from "notiflix/build/notiflix-report-aio";

function StepsSection() {
    const [stepOneComplete, setStepOneComplete] = useState(false);
    const [stepTwoComplete, setStepTwoComplete] = useState(false);
    const [stepThreeComplete, setStepThreeComplete] = useState(false);

    const [stepOneClosed, setStepOneClosed] = useState(false);
    const [stepTwoClosed, setStepTwoClosed] = useState(true);
    const [stepThreeClosed, setStepThreeClosed] = useState(true);

    const [preview, setPreview] = useState("");
    const [foaData, setFoaData] = useState(null);
    const [modalState, setModalState] = useState(false);

    const { token } = useParams();

    useEffect(() => {
        console.log("TEST6");

        if (!foaData) {
            Loading.standard("Loading...");
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}`, { token })
                .then((response) => {
                    const foaData = response.data;
                    const signatureData = foaData.signatureDataResult;
                    const previewLink = foaData.preview;
                    setPreview(previewLink);
                    console.log("signatureData:", signatureData);
                    localStorage.setItem("name", `${signatureData[0].first_name} ${signatureData[0].last_name}`);

                    setFoaData(signatureData);
                    if (signatureData[0].status === "FOA Signed") handleCompleteStep(1);
                    if (signatureData[0].status === "FOA Signed - POA") handleCompleteStep(2);
                    Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    const serverError = e.response.data;
                    Loading.remove();
                    apiErrors(serverError);
                });
        }
    }, []);

    const handleCompleteStep = (type: number) => {
        switch (type) {
            case 1:
                setStepOneComplete(true);
                setStepOneClosed(true);
                setStepTwoClosed(false);
                break;
            case 2:
                setStepOneClosed(true);
                setStepOneComplete(true);
                setStepTwoClosed(true);
                setStepTwoComplete(true);
                setStepThreeClosed(false);
                break;
            case 3:
                setStepOneClosed(true);
                setStepOneComplete(true);
                setStepTwoClosed(true);
                setStepTwoComplete(true);
                setStepThreeComplete(true);
                setStepThreeClosed(true);
                Report.success(
                    "FOA Complete",
                    "Thank you for succesfully submitting your FOA. Our team will be in touch in due course.",
                    "OK",
                    () => {
                        window.location.href = "https://sentinellegal.co.uk";
                    }
                );
                break;

            default:
                break;
        }
    };

    const handlePreviewModal = () => {
        setModalState(!modalState);
    };

    return (
        <div className={styles.steps}>
            <StepSelector stepTitle="1. Sign Your FoA" isClosed={stepOneClosed} isComplete={stepOneComplete}>
                <StepOne completeStep={handleCompleteStep} allFoaData={foaData} previewModalAction={handlePreviewModal} />
            </StepSelector>
            <StepSelector stepTitle="2. Upload Your Proof of Address" isClosed={stepTwoClosed} isComplete={stepTwoComplete}>
                <StepTwo completeStep={handleCompleteStep} />
            </StepSelector>
            <StepSelector stepTitle="3. Upload Your Proof of ID" isClosed={stepThreeClosed} isComplete={stepThreeComplete}>
                <StepThree completeStep={handleCompleteStep} />
            </StepSelector>
            <Modal modalState={modalState} previewModalAction={handlePreviewModal}>
                <Previewer url={preview} />
            </Modal>
        </div>
    );
}

export default StepsSection;

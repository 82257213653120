import styles from "./styles.module.scss";
import id from "../../../../assets/id.png";
import pdf from "../../../../assets/pdf.png";
import addfile from "../../../../assets/add-file.png";
import React, { useState } from "react";

import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { validFile } from "../../../../helpers/fileValidation";
import { useParams } from "react-router-dom";
import { SubmitButton } from "../../../reusable";
import { uploadFileToServer } from "../../../../helpers";

interface sigProps {
    completeStep: (type: number) => void;
}
function StepTwo({ completeStep }: sigProps) {
    const [fileObject, setFileObject] = useState(addfile);
    const [fileObjectToUpload, setFileObjectToUpload] = useState<File>();
    const [filename, setFilename] = useState("");
    const { token } = useParams();

    const fileChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        Loading.standard("Checking file...");
        const files = e.target?.files != null ? e.target.files : [];
        if (!validFile(files, setFileObject, addfile)) return;

        const file = files[0];
        let imagePreview = file.type === "application/pdf" ? pdf : URL.createObjectURL(file);
        if (file.type == "image/heic") {
            imagePreview = addfile;
        }
        setFileObject(imagePreview);

        setFileObjectToUpload(file);
        setFilename(file.name);
        Loading.remove();
    };

    const uploadFile = async (e: React.MouseEvent) => {
        e.preventDefault();

        const uploadPackage = { type: "poa", token: token, fileToUpload: fileObjectToUpload, completeStep: completeStep };
        const uploadResult = await uploadFileToServer(uploadPackage);
    };

    return (
        <div>
            <div className={styles.explainationContent}>
                <p>Please upload a proof of address in the format of a utility bill or council tax bill</p>
                <p>Please ensure the image meets the requirements below:</p>
                <ul>
                    <li>All corners of the document are visible and inside the picture.</li>
                    <li>The image is not blurry or out of focus</li>
                    <li>The image is clear of shine or reflections</li>
                    <li>The image size is no bigger than 10MB</li>
                    <li>The file type is one of the following types: PDF, JPEG, JPG, PNG, HEIF</li>
                </ul>
            </div>
            <div>
                <form>
                    <label htmlFor="poa">
                        <img src={fileObject} />
                        <input type="file" name="poa" id="poa" accept=".pdf, .jpg, .jpeg, .png, .heic" onChange={fileChanged} />
                    </label>
                    <p>{filename}</p>
                    <div className={styles.buttonContainer}>
                        <SubmitButton text="Upload" btnType="primary" onClick={uploadFile} />
                    </div>
                </form>
            </div>
        </div>
    );
}

export default StepTwo;

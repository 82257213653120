import styles from "../globals/pages.module.scss";

import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { SignaturePad, Wrapper } from "../components/reusable";
import Ex160Banner from "../components/pocBanner";
import { apiErrors } from "../helpers/apiErrors";
import Previewer from "../components/reusable/previewer";
import Part36Banner from "../components/part36Banner";

function Part36() {
    const [part36Data, setPart35Data] = useState();
    const [retrievedPart36, setRetrievedPart36] = useState(false);
    const { token } = useParams();
    const [UsablePart36URL, setUsablePart36URL] = useState();
    const [caseRef, setCaseRef] = useState();
    useEffect(() => {
        if (!retrievedPart36) {
            Loading.standard("Loading...");
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}`, { token })
                .then((response) => {
                    const part36ResponseData = response.data;
                    const signatureData = part36ResponseData.signatureDataResult;
                    console.log("signatureData:", signatureData);
                    if (signatureData[0].status == "part36 Signed") {
                        Report.success("Document Signed", "Your Part 36 form has already been signed.", "Okay", () => {
                            window.location.href = "https://sentinellegal.co.uk";
                        });
                    }
                    setUsablePart36URL(part36ResponseData.preview);
                    setCaseRef(signatureData.case_ref);
                    setPart35Data(signatureData);
                    setRetrievedPart36(true);
                    Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    const serverError = e.response.data;
                    Loading.remove();
                    apiErrors(serverError);
                });
        }
    }, []);

    const completePart36 = () => {
        console.log("Completed Part36");
        Report.success("Document Signed", "Thank you for signing your Part 36 form.", "Okay", () => {
            window.location.href = "https://sentinellegal.co.uk";
        });
    };

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <Part36Banner />
            <Wrapper>
                <h1>Complete your Part 36 form.</h1>
                <p>Thank you for assiting Sentinel Legal to prepare your Part 36 settlement document for your motor vehicle finance claims. </p>
                <p>In order for us to proceed with you claim, you are required to review and sign your court issued Part 36 document.</p>
                <p>Once the Part 36 is signed, our team at Sentinel Legal will proceed to progress your claim against the finance provider..</p>
                <hr />
                <div className={styles.part36Container}>
                    <Previewer url={UsablePart36URL} />
                    <SignaturePad additionalAction={completePart36} extraPayload={{ type: "Part36-signature", part36_case_ref: caseRef }} />
                </div>
            </Wrapper>
        </div>
    );
}

export default Part36;

import styles from "../globals/pages.module.scss";

import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { SignaturePad, Wrapper } from "../components/reusable";
import Ex160Banner from "../components/pocBanner";
import { apiErrors } from "../helpers/apiErrors";
import Previewer from "../components/reusable/previewer";

function Ex160() {
    const [ex160Data, setEx160Data] = useState();
    const [retrievedEx160, setRetrievedEx160] = useState(false);
    const { token } = useParams();

    const [UsableEX160URL, setUsableEX160URL] = useState();
    const [caseRef, setCaseRef] = useState();
    useEffect(() => {
        if (!retrievedEx160) {
            Loading.standard("Loading...");
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}`, { token })
                .then((response) => {
                    const ex160ResponseData = response.data;
                    const signatureData = ex160ResponseData.signatureDataResult;
                    localStorage.setItem("name", `${signatureData.first_name} ${signatureData.last_name}`);

                    console.log("signatureData:", signatureData);
                    setUsableEX160URL(ex160ResponseData.preview);
                    setCaseRef(signatureData.case_ref);
                    setEx160Data(signatureData);
                    setRetrievedEx160(true);
                    Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    const serverError = e.response.data;
                    Loading.remove();
                    apiErrors(serverError);
                });
        }
    }, []);

    const completeEX160 = () => {
        console.log("Completed EX160");
        Report.success("Document Signed", "Thank you for signing your EX160 form.", "Okay", () => {
            window.location.href = "https://sentinellegal.co.uk";
        });
    };

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <Ex160Banner />
            <Wrapper>
                <h1>Complete your EX160 form.</h1>
                <p>Thank you for assiting Sentinel Legal to prepare your government issue EX160 form for your motor vehicle finance claims. </p>
                <p>In order for us to proceed with you claim, you are required to review and sign your court issued EX160 form.</p>
                <p>Once the EX160 is signed, our team at Sentinel Legal will proceed to issue your documents to the courts on your behalf.</p>
                <hr />
                <div className={styles.Ex160Container}>
                    <Previewer url={UsableEX160URL} />
                    <SignaturePad additionalAction={completeEX160} extraPayload={{ type: "EX160-signature", EX160_case_ref: caseRef }} />
                </div>
            </Wrapper>
        </div>
    );
}

export default Ex160;

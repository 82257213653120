import styles from "../globals/pages.module.scss";
import { useEffect, useState } from "react";
import pageStyles from "./styles.module.scss";
import { Wrapper } from "../components/reusable";
import StepsSection from "../components/stepsSection";
import FoaBanner from "../components/foaBanner";

function Foa() {
    return (
        <div className={styles.pageContent}>
            <FoaBanner />
            <Wrapper>
                <StepsSection />
            </Wrapper>
        </div>
    );
}

export default Foa;

import styles from '../globals/pages.module.scss'

import { useEffect, useState } from 'react'
import { Loading } from 'notiflix'

import { Wrapper } from '../components/reusable'
import OpenLetterBanner from '../components/openLetterBanner'
import OpenLetterContent from '../components/openLetterContent'

export default function OpenLetter() {
    useEffect(() => {}, [])

    return (
        <div className={` ${styles.openLetterContent}`}>
            <OpenLetterBanner />
            <OpenLetterContent />
        </div>
    )
}

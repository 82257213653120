import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'
import { IsMobile } from '../layout'

function IdUploadBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                {IsMobile() ? (
                    <h1>
                        Upload Your ID <br />
                        To Progress Your Claim
                    </h1>
                ) : (
                    <h1> Upload Your ID to Progress Your Claim</h1>
                )}
                <p>Please upload your 'Proof of ID' to continue your motor finance claim with Sentinel Legal Ltd.</p>
            </Wrapper>
        </div>
    )
}

export default IdUploadBanner

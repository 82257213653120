import styles from '../globals/pages.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { SignaturePad, Wrapper } from '../components/reusable'
import { apiErrors } from '../helpers/apiErrors'
import Previewer from '../components/reusable/previewer'
import FoaV2Banner from '../components/foaV2Banner'
import { useUpdateClaimData } from '../claimProvider'

function FoaV2({ type }: any) {
    const [foaV2Data, setFoaV2Data] = useState()
    const [retrievedFoaV2, setRetrievedFoaV2] = useState(false)
    const [caseRefs, setCaseRefs] = useState<Array<String>>([])
    const [clientId, setClientId] = useState<String>('')
    const { token } = useParams()
    const [UsableFoaV2URL, setUsableFoaV2URL] = useState()
    const updateClaim = useUpdateClaimData()
    const [currentClaim, setCurrentClaim]: any = useState({})
    // const [caseRef, setCaseRef] = useState();
    useEffect(() => {
        if (!retrievedFoaV2) {
            Loading.standard('Loading FOAV2...')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}`, { token })
                .then((response) => {
                    const foaV2ResponseData = response.data

                    console.log('signatureData:', foaV2ResponseData)

                    if (foaV2ResponseData.status == 'FOA V2 Signed') {
                        Report.success(
                            'Document Signed',
                            'Your updated Form of Authority form has already been signed.',
                            'Okay',
                            () => {
                                window.location.href = 'https://sentinellegal.co.uk'
                            }
                        )
                    }
                    setUsableFoaV2URL(foaV2ResponseData.preview)
                    setClientId(foaV2ResponseData.client_id)
                    setFoaV2Data(foaV2ResponseData)
                    setCaseRefs(foaV2ResponseData.foaV2Data)
                    setRetrievedFoaV2(true)
                    updateClaim(foaV2ResponseData)

                    Loading.remove()
                })
                .catch((e) => {
                    console.log('error:', e)
                    const serverError = e.response.data
                    apiErrors(serverError)
                    Loading.remove()
                })
        }
    }, [])

    const completeFoaV2 = () => {
        console.log('Completed Part36')
        Report.success('Document Signed', 'Thank you for signing your updated FoA form.', 'Okay', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
    }

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <FoaV2Banner />
            <Wrapper>
                <h1>Complete your Form of Authority Document.</h1>
                <p>
                    Due to a change in circumstances, we require your signature on new documents relating to your motor
                    finance claims.{' '}
                </p>
                <p>
                    In order for us to proceed with you claim, you are required to review and sign your Form of
                    Authority for the claims listed below:
                </p>
                <ul>
                    {caseRefs.map((item: any, index: number) => {
                        return (
                            <li key={index}>
                                {item.case_ref} - (Finance Provider: {item.lender})
                            </li>
                        )
                    })}
                </ul>
                <p>
                    The document below is an example of your document, and will have your signature printed at the
                    bottom for each of cases listed above.
                </p>
                <hr />
                <div className={styles.foaV2Container}>
                    <Previewer url={UsableFoaV2URL} />
                    <SignaturePad
                        additionalAction={completeFoaV2}
                        extraPayload={{
                            type,
                            client_id: clientId,
                        }}
                    />
                </div>
            </Wrapper>
        </div>
    )
}

export default FoaV2

import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";
import { SignaturePad } from "../../../reusable";

interface sigProps {
    completeStep: (type: number) => void;
    allFoaData: any;
    previewModalAction: () => void;
}
function StepOne({ completeStep, allFoaData, previewModalAction }: sigProps) {
    const [lender, setLender] = useState("");

    useEffect(() => {
        if (allFoaData && allFoaData.length > 0) {
            setLender(allFoaData[0].lender);
        }
    }, [allFoaData]);

    const previewModal = (e: React.MouseEvent) => {
        previewModalAction();
    };

    const sigAdditionalAction = () => {
        completeStep(1);
    };

    return (
        <div>
            <div className={styles.explainationContent}>
                <p>This FOA is a confirmation of your request for Sentinel Legal Limited to act on your behalf of your motor finance claim against</p>
                <p className={styles.lenderBold}>
                    <span>{lender}</span>
                </p>
                <p>Regarding your mis-sold commision claim for the following finance agreements:</p>
                <ul>
                    {allFoaData && allFoaData.length && allFoaData.map((e: any, i: number) => <li key={i}>Agreement Number: {e.policy_number}</li>)}
                </ul>
                <p>Please read and sign the below to confirm the follow: </p>
                <ul>
                    <li>
                        Confirm that I have instructed Sentinel Legal Ltd to act for me in relation to a potential claim for the mis selling of my
                        motor finance agreement against you
                    </li>
                    <li>I request that you co-operate fully with them in all respects</li>
                    <li>I request that you release all personal files and information on my behalf to them, that they request</li>
                </ul>
            </div>
            <SignaturePad extraPayload={{ type: "foa-signature" }} additionalAction={sigAdditionalAction} />
            <p className={styles.previewButton}>
                <span onClick={previewModal}>
                    <i>Preview Document</i>
                </span>
            </p>
        </div>
    );
}

export default StepOne;

import styles from '../globals/pages.module.scss'
import pageStyles from './styles.module.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { apiErrors } from '../helpers/apiErrors'
import { useUpdateClaimData } from '../claimProvider'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'
import OpenBankingBanner from '../components/openBankingBanner'
import { SubmitButton, Wrapper } from '../components/reusable'
import Affordability from '../components/affordabilityDetails'
import HalfContent from '../components/halfContent'

import car1 from '../assets/car1.jpg'
import car2 from '../assets/car2.jpg'
import VideoSection from '../components/videoContainer'

const title1 = 'Why Do We Need Your Consent?'
const text1 = ` During our investigation into your claim, we have concluded from the information available there
                is a possibility that you may have been subjected to irresponsible lending at the time you took
                out your car loan. If this is proven and your loan was unaffordable you may be entitled to
                compensation.`

const title2 = 'How To Proceed'
const text2 = ` Should you wish Sentinel to proceed with this aspect of your complaint, please click the 
                'I Consent to an Affordability Check' and wait to receive an email from Armalytix requesting you to start the
                process. The process will only take you just a few minutes to complete. You are always in full
                control of your data as you work through this process.`

export default function ConsentOpenBanking() {
    const [personData, setPersonData] = useState<any>({})
    const [cases, setCases] = useState<any>([])
    const [currentClaim, setCurrentClaim]: any = useState({})
    const { token } = useParams()
    const updateClaim = useUpdateClaimData()
    useEffect(() => {
        Loading.standard('Loading...')
        axios
            .post(`${process.env.REACT_APP_API_PORTAL_URL}/clicked-open-banking-link`, {
                token,
            })
            .then((response) => {
                const responseData = response.data

                console.log(responseData)

                if (responseData == 'Token not found') {
                    Report.failure(
                        'Account Not Found',
                        "We couldn't locate your account, please contact support.",
                        'Okay',
                        () => {
                            window.location.href = 'https://sentinellegal.co.uk'
                        }
                    )
                }

                const personData = responseData.person
                const cases = responseData.cases

                if (!personData) throw new Error(responseData.error)
                localStorage.setItem('name', `${personData.first_name} ${personData.last_name}`)

                setPersonData(personData)
                setCases(cases)
                updateClaim(personData)

                Loading.remove()
            })
            .catch((e) => {
                console.error('error:', e)
                const serverError = e.response.data
                Loading.remove()
                apiErrors(serverError)
            })
    }, [])

    const submitConsent = () => {
        console.log('SUBMITTING CONSENT....')

        Loading.standard('Loading...')

        const metadata = {
            device: osName,
            browser: browserName,
        }
        axios
            .post(`${process.env.REACT_APP_API_PORTAL_URL}/open-banking-optin`, { token, metadata })
            .then((response) => {
                const responseData = response.data

                console.log(responseData)

                if (responseData == 'Token not found') {
                    Report.failure(
                        'Account Not Found',
                        "We couldn't locate your account, please contact support.",
                        'Okay',
                        () => {
                            window.location.href = 'https://sentinellegal.co.uk'
                        }
                    )
                }

                if (responseData.success) {
                    Report.success(
                        'Thank you',
                        'You will shortly receive an email to complete the onboarding process.',
                        'Okay',
                        () => {
                            window.location.href = 'https://sentinellegal.co.uk'
                        }
                    )
                }

                Loading.remove()
            })
            .catch((e) => {
                console.error('error:', e)
                const serverError = e.response.data
                Loading.remove()
                apiErrors(serverError)
            })

        return
    }

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <OpenBankingBanner />
            <Wrapper>
                <div>
                    <h2
                        style={{
                            fontWeight: '400',
                            marginBottom: '10px',
                            fontSize: '26px',
                            borderBottom: '1px solid $primary',
                            padding: '10px 0',
                            color: '#333',
                        }}
                    >
                        Consent to Affordability Assessment
                    </h2>
                    <p style={{ letterSpacing: '1px', lineHeight: '2.2', color: 'grey' }}>
                        To allow Sentinel to process your claim quickly and effectively we have partnered with{' '}
                        <a
                            style={{
                                color: '#C78E5F',
                            }}
                            href="https://armalytix.com/why-armalytix/users/"
                            target="__blank"
                        >
                            Armalytix
                        </a>
                        , an FCA regulated business, to provide a faster and more secure alternative to requesting bank
                        statements from you.{' '}
                        <a
                            style={{
                                color: '#C78E5F',
                            }}
                            href="https://armalytix.com/why-armalytix/users/"
                            target="__blank"
                        >
                            Armalytix
                        </a>{' '}
                        uses open banking technology, designed by the banks to allow you to share this information in
                        the safest and easiest way possible.
                    </p>
                    <div>{personData && <Affordability data={cases} person={personData} />}</div>
                    <div style={{ display: 'block', margin: '50px 0 150px', textAlign: 'center' }}>
                        <p>
                            <b>Please click the button below to consent to our free affordability check.</b>
                        </p>
                        <SubmitButton
                            text={'I Consent to an Affordability Check'}
                            onClick={() => submitConsent()}
                            btnType={'primary'}
                        />
                        <p style={{ color: 'grey', fontSize: '12px', fontWeight: '700' }}>
                            <i>This will not affect your credit score</i>
                        </p>
                    </div>
                    <HalfContent content={text1} contentTitle={title1} img={car2} reverse={false} />
                    <HalfContent content={text2} contentTitle={title2} img={car1} reverse={true} />
                    <VideoSection />
                </div>
            </Wrapper>
        </div>
    )
}

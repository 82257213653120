// import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import Previewer from "../reusable/previewer";
import { SignaturePad } from "../reusable";
interface sigProps {
    currentPoc: any;
    pocSignedAction: any;
    type?: any;
}

function PocSignatureContainer({ currentPoc, pocSignedAction, type = "poc-signature" }: sigProps) {
    return (
        <div className={styles.pocContainer}>
            <div className={`${styles.previews} `}>
                <Previewer url={currentPoc.pocURL} />
                <Previewer url={currentPoc.N1URL} />
            </div>
            <SignaturePad additionalAction={pocSignedAction} extraPayload={{ type: type, case_ref: currentPoc.case_ref }} />
        </div>
    );
}

export default PocSignatureContainer;

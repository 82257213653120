import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Wrapper } from "../reusable";
import { IsMobile } from "../layout";

function PocBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                {IsMobile() ? (
                    <h1>
                        Review and Sign the <br />
                        Particulars of your Claim
                    </h1>
                ) : (
                    <h1> Review and Sign the Particulars of your Claim</h1>
                )}
                <p>Please read and sign your 'Particulars of Claim' to continue your motor finance claim with Sentinel Legal Ltd.</p>
            </Wrapper>
        </div>
    );
}

export default PocBanner;

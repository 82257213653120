import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Wrapper } from "../reusable";
import { IsMobile } from "../layout";

function FoaBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                {IsMobile() ? (
                    <h1>
                        Complete Your <br />
                        Form of Authority
                    </h1>
                ) : (
                    <h1>Complete Your Form of Authority</h1>
                )}

                <p>Please provide Sentinel Legal Ltd with your full form of authority by completing the steps below.</p>
            </Wrapper>
        </div>
    );
}

export default FoaBanner;

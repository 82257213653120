import { useEffect, useState } from "react";
import { TickIcon } from "../../reusable/svgs";
import styles from "./styles.module.scss";

interface stepSelectorInterface {
    stepTitle: string;
    isComplete: boolean;
    isClosed: boolean;
    children: React.ReactNode;
}

function StepSelector({ stepTitle, isClosed, isComplete, children }: stepSelectorInterface) {
    const [stepClosed, setStepClosed] = useState(isClosed);
    const [stepComplete, setStepComplete] = useState(isComplete);

    useEffect(() => {
        setStepClosed(isClosed);
        setStepComplete(isComplete);
    }, [isClosed, isComplete]);

    return (
        <div className={`${styles.stepContainer} ${stepComplete ? styles.containerComplete : ""} ${!stepClosed ? styles.containerOpen : ""}`}>
            <div className={styles.stepTitle}>
                <h3>{stepTitle}</h3>
                <span>
                    <TickIcon color={`${stepComplete ? "#FFF" : "transparent"}`} />
                </span>
            </div>
            <div className={`${styles.stepMain} ${!stepClosed ? styles.stepOpened : ""}`}>{children}</div>
        </div>
    );
}

export default StepSelector;

import styles from '../globals/pages.module.scss'

import { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { Loading } from 'notiflix'
import { Report } from 'notiflix/build/notiflix-report-aio'

import { SignaturePad, Wrapper } from '../components/reusable'
import { apiErrors } from '../helpers/apiErrors'
import Previewer from '../components/reusable/previewer'
import FoaV2Banner from '../components/foaV2Banner'
import { useUpdateClaimData } from '../claimProvider'
import IdUploader from '../components/idUploadOA'
import IdUploadBanner from '../components/idUploadBanner'

function IdUploadOA() {
    const [idUploadData, setIdUploadData] = useState()
    const [retrievedIdUpload, setRetrievedIdUpload] = useState(false)
    const { token } = useParams()
    const updateClaim = useUpdateClaimData()
    const [currentClaim, setCurrentClaim]: any = useState({})
    // const [caseRef, setCaseRef] = useState();
    useEffect(() => {
        if (!retrievedIdUpload) {
            Loading.standard('Loading ID upload Portal')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/id-upload`, { token })
                .then((response) => {
                    const idUploadResponseData = response.data

                    console.log('idUploadData:', idUploadResponseData)

                    if (idUploadResponseData.status == 'Already uploaded') {
                        Report.success(
                            'Document Signed',
                            'Your updated Form of Authority form has already been signed.',
                            'Okay',
                            () => {
                                window.location.href = 'https://sentinellegal.co.uk'
                            }
                        )
                    }
                    setIdUploadData(idUploadResponseData)
                    updateClaim(idUploadResponseData)
                    Loading.remove()
                })
                .catch((e) => {
                    console.log('error:', e)
                    const serverError = e.response.data
                    apiErrors(serverError)
                    Loading.remove()
                })
        }
    }, [])

    const completeIDUpload = () => {
        console.log('Completed ID Upload')
        Report.success('Document Signed', 'Thank you for signing your updated FoA form.', 'Okay', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
    }

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <IdUploadBanner />
            <Wrapper>
                <h1>How to Upload Your Proof of ID.</h1>
                <p>
                    Olton Alexander have informed Sentinel Legal you have grounds for a motor finance claim based on the
                    information you previously provided. As a result your motor finance claim recently transferred to
                    Sentinel Legal, so we can act on your behalf in recovering your missold motor finance compensation.
                </p>
                <p>
                    In order for Sentinel Legal to process your claim, we require a copy of your ID as evidence of
                    instruction to act on your behalf against your finance provider.
                </p>
                <hr />
                <IdUploader completeStep={completeIDUpload} idUploadType={'poid-oa'} />
            </Wrapper>
        </div>
    )
}

export default IdUploadOA

// import React, { useState, useEffect } from "react";
import styles from "./styles.module.scss";

import Previewer from "../reusable/previewer";
import { SignaturePad } from "../reusable";
interface sigProps {
    previewUrl: any;
    signedAction: any;
    extraPayload: any;
}

export default function SingleSignatureContainer({ previewUrl, signedAction, extraPayload }: sigProps) {
    return (
        <div className={styles.singleSigContainer}>
            <div className={`${styles.previews} `}>
                <Previewer url={previewUrl} />
            </div>
            <SignaturePad additionalAction={signedAction} extraPayload={extraPayload} />
        </div>
    );
}

import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

import Header from "../components/layout/header/header";
import Footer from "../components/layout/footer/footer";

declare global {
    interface Window {
        dataLayer: any;
    }
}
function Main() {
    let location = useLocation();
    const build_tracking = (script_url: string) => {
        var s = document.getElementsByTagName("script")[0];
        var b = document.createElement("script");
        b.type = "text/javascript";
        b.async = true;
        b.src = script_url;
        s.parentNode?.insertBefore(b, s);
    };

    return (
        <div className="main">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}

export default Main;

import { useEffect, useState } from 'react'

const Previewer = ({ url }: any) => {
    const [updateUrl, setUpdateUrl] = useState('')
    const [width, setWidth] = useState((window.innerWidth / 4) * 3)
    const [height, setHeight] = useState((window.innerHeight / 4) * 3)

    useEffect(() => {
        console.log(url)
        if (url != '') setUpdateUrl(url)
    }, [url])

    return <iframe title="preview-item" src={updateUrl} height={height} width={width} />
}
export default Previewer

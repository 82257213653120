import styles from "./styles.module.scss";
import responsiveStyles from "./responsiveStyles.module.scss";
import { Wrapper } from "../reusable";
import { IsMobile } from "../layout";

function FoaV2Banner() {
  return (
    <div className={`${styles.banner} ${responsiveStyles.banner}`}>
      <Wrapper>
        {IsMobile() ? (
          <h1>
            Review and Sign the <br />
            FoA Document
          </h1>
        ) : (
          <h1> Review and Sign the Form of Authority Document</h1>
        )}
        <p>
          Please read and sign your 'Form of Authority Document' to continue
          your motor finance claim with Sentinel Legal Ltd.
        </p>
      </Wrapper>
    </div>
  );
}

export default FoaV2Banner;

import styles from './styles.module.scss'
import responsiveStyles from './responsive-styles.module.scss'
import logo from '../../../assets/logo.png'
import { Wrapper } from '../../reusable'

function Footer() {
    return (
        <div className={`${styles.footer} ${responsiveStyles.footer}`}>
            <Wrapper>
                <div className={`${styles.innerFooter} ${responsiveStyles.innerFooter}`}>
                    <img src={logo} />
                    <p className={styles.disclaimer}>
                        Sentinel legal Ltd is a registered company in England and Wales. Registered office Harley house,
                        29 Cambray Place, Cheltenham, England, GL50 1JN. Authorised and regulated by the Solicitors
                        Regulation Authority. SRA number 811792. Phone: 0161 528 9544
                    </p>
                    <p>
                        <a target="__blank" href="https://www.sentinellegal.co.uk/privacy-policy">
                            Terms of Business
                        </a>
                        <a target="__blank" href="https://www.sentinellegal.co.uk/privacy-policy">
                            Privacy Notice
                        </a>
                    </p>
                </div>
            </Wrapper>
        </div>
    )
}

export default Footer

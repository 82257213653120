import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { Wrapper } from '../reusable'
import { IsMobile } from '../layout'

export default function OpenLetterBanner() {
    return (
        <div className={`${styles.banner} ${responsiveStyles.banner}`}>
            <Wrapper>
                <h1>Sign Our Open Letter</h1>
                <p>
                    Our open letter calling for stronger regulation from the FCA in motor finance commission complaints
                </p>
            </Wrapper>
        </div>
    )
}

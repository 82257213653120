import styles from "../globals/pages.module.scss";

import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";
import { SignaturePad, Wrapper } from "../components/reusable";
import { apiErrors } from "../helpers/apiErrors";
import Previewer from "../components/reusable/previewer";
import NotPart36Banner from "../components/notPart36Banner";

function NotPart36() {
    const [notPart36Data, setPart35Data] = useState();
    const [retrievedNotPart36, setRetrievedNotPart36] = useState(false);
    const { token } = useParams();
    const [UsableNotPart36URL, setUsableNotPart36URL] = useState();
    const [caseRef, setCaseRef] = useState();
    useEffect(() => {
        if (!retrievedNotPart36) {
            Loading.standard("Loading...");
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}`, { token })
                .then((response) => {
                    const notPart36ResponseData = response.data;
                    const signatureData = notPart36ResponseData.signatureDataResult;
                    console.log("signatureData:", signatureData);
                    if (signatureData[0].status == "notPart36 Signed") {
                        Report.success("Document Signed", "Your Document has already been signed.", "Okay", () => {
                            window.location.href = "https://sentinellegal.co.uk";
                        });
                    }
                    setUsableNotPart36URL(notPart36ResponseData.preview);
                    setCaseRef(signatureData.case_ref);
                    setPart35Data(signatureData);
                    setRetrievedNotPart36(true);
                    Loading.remove();
                })
                .catch((e) => {
                    console.log("error:", e);
                    const serverError = e.response.data;
                    Loading.remove();
                    apiErrors(serverError);
                });
        }
    }, []);

    const completeNotPart36 = () => {
        console.log("Completed Settlement");
        Report.success("Document Signed", "Thank you for signing your document.", "Okay", () => {
            window.location.href = "https://sentinellegal.co.uk";
        });
    };

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <NotPart36Banner />
            <Wrapper>
                <h1>Complete your settlement agreement document.</h1>
                <p>Thank you for assiting Sentinel Legal to prepare your settlement agreement document for your motor vehicle finance claims. </p>
                <p>Please confirm you are happy for Sentinel Legal to make a settlement offer to your Lender.</p>
                <p>
                    Once the settlement agreement document is signed, our team at Sentinel Legal will proceed to progress your claim against the
                    finance provider.
                </p>
                <hr />
                <div className={styles.notPart36Container}>
                    <Previewer url={UsableNotPart36URL} />
                    <p>
                        <a href={UsableNotPart36URL}>Download Preview</a>
                    </p>
                    <SignaturePad additionalAction={completeNotPart36} extraPayload={{ type: "notPart36-signature", notPart36_case_ref: caseRef }} />
                </div>
            </Wrapper>
        </div>
    );
}

export default NotPart36;

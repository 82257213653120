import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { SignaturePad, SubmitButton, Wrapper } from '../reusable'
import { IsMobile } from '../layout'

import axios from 'axios'
import { Report } from 'notiflix/build/notiflix-report-aio'
import { apiErrors } from '../../helpers/apiErrors'
import { browserName, osName, deviceDetect, getUA } from 'react-device-detect'
import { useEffect, useState } from 'react'
import Previewer from '../reusable/previewer'
import { Loading } from 'notiflix'
import Modal from '../reusable/modal'

export default function OpenLetterContent() {
    const [formDataCollected, setFormData] = useState<any>({
        firstname: '',
        lastname: '',
        email: '',
        company: '',
        position: '',
    })
    const [previewUrl, setPreviewUrl] = useState<any>('')
    const [modalState, setModalState] = useState(false)

    useEffect(() => {
        if (!previewUrl.length) {
            Loading.standard('Loading...')
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/open-letter`, {})
                .then((response) => {
                    const responseData = response.data
                    console.log(responseData)
                    setPreviewUrl(responseData.previewUrl)
                    Loading.remove()
                })
                .catch((e) => {
                    console.error('error:', e)
                    const serverError = e.response.data
                    Loading.remove()
                    apiErrors(serverError)
                })
        }
    }, [])

    const handleChange = (e: any) => {
        let tempFormData = formDataCollected
        tempFormData[e.target.name] = e.target.value
        setFormData(tempFormData)
    }
    const completeOpenLetter = () => {
        Report.success('Thank you', 'Thank you for signing our open letter.', 'Okay', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
        return
    }

    const handlePreviewModal = () => {
        setModalState(!modalState)
    }
    return (
        <div className={`${styles.openLetterContent} ${responsiveStyles.openLetterContent}`}>
            <Wrapper>
                <form>
                    <h3>Introduction to Our Open Letter to the Financial Conduct Authority</h3>
                    <p>
                        Welcome to our dedicated page for the open letter addressed to the Financial Conduct Authority
                        (FCA) regarding Discretionary Motor Finance Commissions . This letter represents a collective
                        call for action from a coalition of law firms, barristers, and claims management companies who
                        are deeply concerned with persistent malpractices within the motor finance sector.
                    </p>
                    <h3>Why We Have Put Together This Open Letter</h3>
                    <p>
                        Through extensive litigation and investigative efforts, we have uncovered systemic issues that
                        significantly disadvantage consumers and undermine the integrity of financial dealings. Our
                        findings, supported by court-ordered disclosures, have given us a profound understanding of the
                        commission structures and the average commission amounts traditionally paid by lenders to
                        dealerships.
                    </p>
                    <h3>Issues with Current Disclosure Practices</h3>
                    <p>
                        Despite this in-depth knowledge, following the FCA's guidance to submit complaints directly to
                        motor finance lenders regarding Discretionary Commission Arrangements (DCAs), it appears that
                        lenders are now using the ambiguity in FCA guidelines to avoid providing comprehensive
                        disclosure of commissions. This results in disclosures that are inconsistent with the data
                        previously provided during litigation. The current regulatory framework does not compel lenders
                        to evidence the actual financial remuneration paid, allowing significant discrepancies to
                        persist unchallenged.
                    </p>
                    <h3>Acknowledging FCA's Efforts</h3>
                    <p>
                        We acknowledge and applaud the FCA's proactive steps in addressing some of these concerns.
                        Notably, the FCA banned Discretionary Commission Models (DCAs) on January 28, 2021, following a
                        consultation carried out in 2019. Additionally, we recognise the FCA's announcement on January
                        11, 2024, that it is conducting an investigation to review historical motor finance commission
                        arrangements and sales across several firms. This review aims to address widespread misconduct
                        and ensure that consumers who have lost out receive appropriate compensation in an orderly,
                        consistent, and efficient manner, resolving any contested legal issues of general importance.
                        The FCA plans to set out the next steps on this issue in the third quarter of 2024.
                    </p>
                    <h3>What We Want to Achieve</h3>
                    <p>Through this letter, we seek:</p>
                    <ul>
                        <li>
                            The implementation of robust and clear guidelines for the disclosure of financial
                            remuneration paid to car dealerships by motor finance lenders in individual financial
                            agreements. For example, providing unredacted copies of the agreements that detail the
                            commission structure between the motor finance provider and the dealership that sold the
                            motor finance agreement.
                        </li>
                        <li>
                            Enhanced regulatory oversight to ensure that all financial entities adhere to these
                            guidelines, with strict enforcement measures and severe regulatory penalties for
                            non-compliance in the disclosure of financial remuneration.
                        </li>
                        <li>
                            A commitment from the FCA to address and rectify the issues that have allowed such
                            malpractices to persist.
                        </li>
                    </ul>
                    <p>
                        We invite fellow professionals and stakeholders within the legal sector who have experience with
                        handling motor finance litigation and/or motor finance complaints to join us in this call for
                        change.
                    </p>
                    <p>
                        By adding your signature, you are not only supporting a movement towards greater transparency
                        and fairness but also helping to ensure that financial practices reflect the principles of
                        justice and consumer protection.
                    </p>
                    <p>
                        Please read the letter thoroughly and add your signature to support our collective effort to
                        advocate for necessary reforms in motor finance practices.
                    </p>
                    <p className={styles.previewButton}>
                        <span onClick={handlePreviewModal}>
                            <i>Read the Open Letter</i>
                        </span>
                    </p>
                    <br />
                    <hr />
                    <br />
                    <p>Complete and sign the form below to contribute. </p>
                    <div className={styles.names}>
                        <fieldset>
                            <label>Firstname</label>
                            <input name="firstname" type={'text'} onChange={(e) => handleChange(e)} />
                        </fieldset>
                        <fieldset>
                            <label>Lastname</label>
                            <input name="lastname" type={'text'} placeholder="" onChange={(e) => handleChange(e)} />
                        </fieldset>
                    </div>
                    <fieldset>
                        <label>Email Address</label>
                        <input name="email" type={'email'} placeholder="" onChange={(e) => handleChange(e)} />
                    </fieldset>
                    <fieldset>
                        <label>Your Company</label>
                        <input name="company" type={'text'} placeholder="" onChange={(e) => handleChange(e)} />
                    </fieldset>
                    <fieldset>
                        <label>Your Position</label>
                        <input name="position" type={'text'} placeholder="" onChange={(e) => handleChange(e)} />
                    </fieldset>
                </form>

                <SignaturePad
                    additionalAction={completeOpenLetter}
                    extraPayload={{
                        type: 'open-letter-signature',
                        signerData: formDataCollected,
                        metadata: { browser: browserName, device: osName },
                    }}
                />
                <Modal modalState={modalState} previewModalAction={handlePreviewModal}>
                    <Previewer url={previewUrl} />
                </Modal>
            </Wrapper>
        </div>
    )
}

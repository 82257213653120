import { Report } from 'notiflix/build/notiflix-report-aio'
import { Loading } from 'notiflix'

export const apiErrors = (serverError: string) => {
    if (serverError === 'token not found' || serverError === 'no token') {
        Report.failure(
            'Unable to locate account.',
            'We were unable to locate your case, contact support@sentinellegal.co.uk for more information.',
            'OK',
            () => {
                window.location.href = 'https://sentinellegal.co.uk'
            }
        )
    }

    if (serverError === 'Consented previously') {
        Report.success(
            'Already Consented',
            'We have already received your consent. Armalytix will contact you shortly. ',
            'OK',
            () => {
                window.location.href = 'https://sentinellegal.co.uk'
            }
        )
    }
    if (serverError === 'FOA V2 Signed') {
        Report.success('FOA Complete', 'Your FOA has already been completed.', 'OK', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
    }
    if (serverError === 'FOA Complete') {
        Report.success('FOA Complete', 'Your FOA has already been completed.', 'OK', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
    }
    if (serverError === 'all CFAs Complete') {
        Report.success('No Documents Available', "You don't have any documents to sign.", 'Okay', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
    }
    if (serverError === 'EX160 Complete') {
        Report.success('EX160 Complete', 'Your EX160 has already been completed.', 'OK', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
    }
    if (serverError === 'POC Complete') {
        Report.success('Documents signed', 'Your PoC and N1 documents have already been completed.', 'OK', () => {
            window.location.href = 'https://sentinellegal.co.uk'
        })
    }

    return true
}

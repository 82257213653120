import styles from './styles.module.scss'
import responsiveStyles from './responsiveStyles.module.scss'
import { useEffect, useState } from 'react'

export default function Affordability({ person, data }: any) {
    const [personData, setPersonData] = useState<any>({})
    const [cases, setCases] = useState<any>([])
    const [addressString, setAddressString] = useState<any>({})

    useEffect(() => {
        console.log('person data : ', person)
        setPersonData(person)
        setCases(data)
        setAddressString(printAddress(person))
    }, [person, data])

    useEffect(() => {
        console.log(' data : ', data)
        setCases(data)
    }, [data])

    const printAddress = (person: any) => {
        let addressString = ``
        if (person.address_one && person.address_one.length > 1)
            addressString = `${addressString} ${person.address_one}<br />`
        if (person.address_two && person.address_two.length > 1)
            addressString = `${addressString} ${person.address_two}<br />`
        if (person.town && person.town.length > 1) addressString = `${addressString} ${person.town}<br />`
        if (person.county && person.county.length > 1) addressString = `${addressString} ${person.county}<br />`
        if (person.postcode && person.postcode.length > 1) addressString = `${addressString} ${person.postcode}<br />`
        return addressString
    }

    return (
        <>
            <div className={`${styles.affordability} ${responsiveStyles.affordability}`}>
                {personData && (
                    <>
                        <div className={`${styles.affordabilityDetails} ${responsiveStyles.affordabilityDetails}`}>
                            <h4>Your Details</h4>
                            <div
                                className={`${styles.affordabilityDetailsInner} ${responsiveStyles.affordabilityDetailsInner}`}
                            >
                                <div className={`${styles.leftDetails} ${responsiveStyles.leftDetails}`}>
                                    <div>
                                        <p>
                                            <span>Name:</span>
                                            {personData?.first_name} {personData?.last_name}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            <span>Date of Birth: </span>
                                            {personData?.dob}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            <span> Telephone:</span>
                                            {personData?.telephone}
                                        </p>
                                    </div>
                                    <div>
                                        <p>
                                            <span> Email:</span>
                                            {personData?.email_address}
                                        </p>
                                    </div>
                                </div>
                                <div className={`${styles.rightDetails} ${responsiveStyles.rightDetails}`}>
                                    <p className={`${styles.address} ${responsiveStyles.address}`}>
                                        <span>Address:</span>{' '}
                                        <span dangerouslySetInnerHTML={{ __html: addressString }}></span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className={`${styles.affordability} ${responsiveStyles.affordability}`}>
                {cases && (
                    <>
                        <div className={`${styles.affordabilityDetails} ${responsiveStyles.affordabilityDetails}`}>
                            <h4>Your Cases</h4>

                            {cases &&
                                cases.map((singleCase: any, i: number) => {
                                    return (
                                        <div
                                            key={i}
                                            className={`${styles.affordabilityDetailsCase} ${responsiveStyles.affordabilityDetailsCase}`}
                                        >
                                            <div
                                                className={`${styles.affordabilityDetailsInner} ${responsiveStyles.affordabilityDetailsInner}`}
                                            >
                                                <div
                                                    className={`${styles.leftDetails} ${responsiveStyles.leftDetails}`}
                                                >
                                                    <div>
                                                        <p>
                                                            <span>Finance Provider: </span>
                                                            {singleCase?.lender}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Policy Number:</span>
                                                            {singleCase?.agreement_number}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className={`${styles.rightDetails} ${responsiveStyles.rightDetails}`}
                                                >
                                                    <div>
                                                        <p>
                                                            <span>Loan Amount:</span>
                                                            {singleCase?.loan_amount}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            <span>Monthly payment:</span>
                                                            {singleCase?.monthly_payment}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </div>
                    </>
                )}
            </div>
        </>
    )
}

import styles from "../globals/pages.module.scss";
import pageStyles from "./styles.module.scss";

import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Loading } from "notiflix";
import { Report } from "notiflix/build/notiflix-report-aio";

import { Wrapper } from "../components/reusable";
import CfaBanner from "../components/cfaBanner";
import SingleSignatureContainer from "../components/singleSigContainer";
import { apiErrors } from "../helpers/apiErrors";
import PocView from "../components/pocView";
import { useUpdateClaimData } from "../claimProvider";

export default function Cfa() {
    const [cfaData, setCfaData] = useState([]);
    const [retrievedCfa, setRetrievedCfa] = useState(false);
    const [currentClaim, setCurrentClaim]: any = useState({});
    const { token } = useParams();
    const updateClaim = useUpdateClaimData();
    useEffect(() => {
        if (!retrievedCfa) {
            Loading.standard("Loading...");
            axios
                .post(`${process.env.REACT_APP_API_TOKEN_URL}/new-client`, { token })
                .then((response) => {
                    const responseData = response.data;
                    if (responseData == "CFA Complete") {
                        Report.success("Document Signed", "Your Document has already been signed.", "Okay", () => {
                            window.location.href = "https://sentinellegal.co.uk";
                        });
                    }

                    const signatureData = responseData.signatureDataResult;

                    if (!signatureData) throw new Error(responseData.error);
                    localStorage.setItem("name", `${signatureData[0].first_name} ${signatureData[0].last_name}`);
                    const startingClaim = signatureData.find((e: any) => e.status == "CFA Link sent");
                    if (typeof startingClaim == 'undefined') {
                        Report.success("Document Signed", "Your Document has already been signed.", "Okay", () => {
                            window.location.href = "https://sentinellegal.co.uk";
                        });
                    }

                    setCfaData(signatureData);
                    setCurrentClaim(startingClaim);
                    setRetrievedCfa(true);
                    updateClaim(signatureData[0]);

                    Loading.remove();
                })
                .catch((e) => {
                    console.error("error:", e);
                    const serverError = e.response.data;
                    Loading.remove();
                    apiErrors(serverError);
                });
        }
    }, []);

    const completePoc = (obj: any) => {
        Report.success(
            "Conditional Fee Agreement Form Complete",
            "Thank you for signing your document. We will update you on your cases in due course.",
            "Okay",
            () => {
                window.location.href = "https://sentinellegal.co.uk";
            }
        );
        
        return;
    };

    const selectSidebarItem = (obj: any) => {
        console.log("selectSidebarItem"); //DEBUG
    };

    return (
        <div className={`${styles.pageContent} ${styles.pocContent}`}>
            <CfaBanner />
            <Wrapper>
                <PocView>
                    { retrievedCfa ? 
                        <SingleSignatureContainer 
                        signedAction={completePoc} 
                        previewUrl={currentClaim.doc_url} 
                        extraPayload={{ 
                        type: "sentinel-cfa-signature", 
                        pending_client_id: currentClaim.pending_client_id }}
                        />
                    : <></>}
                </PocView>
            </Wrapper>
        </div>
    );
}
